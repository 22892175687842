import React from 'react';
import '../App.css';

function Boxes(props) {
    return (
        <>
        <div className="boxes">
            <a href="#about">
                <div className="box">
                    O nás
                </div>
            </a>

            <a href="#services">
                <div className="box">
                    Naše služby
                </div>
            </a>

            <a href="#why">
                <div className="box">
                    Proč si vybrat nás
                </div>   
            </a>

            <a href="#ref">
                <div className="box">
                    Reference
                </div>
            </a>
        </div>
        </>
    );
}

export default Boxes;