import logo from '../logoh.png';
import '../App.css';

function Loading() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </p>
      </header>
    </div>
  );
}

export default Loading;
