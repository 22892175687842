import React, { useState, useEffect } from 'react';
import './App.css';
import Loading from './components/Loading';
import Main from "./components/Main";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    
    // Clean up the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? <Loading /> : <Main />}
    </>
  );
}

export default App;
