import React from 'react';
import '../App.css';
import logo from '../logoh.png';

function Header(props) {
    return (
        <>
        <div className="navbar">
            <img src={logo} className="nav-logo" />
        </div>
        <div className="Header">
            <div className="Header-background"></div>
            <div className="Header-content animated fadeInLeft">
                <div className="Header-hero">
                    <h1>Horvy Smart Homes </h1>
                    <p>Váš partner pro chytré domácnosti a elektroinstalace</p>
                    <button className="Button">Kontakt</button>
                </div>
                <div className="Header-visuals">
                    <div className="Iphone animated fadeInLeft"></div>
                </div>
            </div>
        </div>
        </>
    );
}

export default Header;