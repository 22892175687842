import React from 'react';
import Header from "./Header";
import Boxes from './Boxes';

function Main(props) {
    return (
        <>
            <Header />
            <Boxes />
        </>
    );
}

export default Main;